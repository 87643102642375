/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import { Provider } from 'react-globally'
import { globalHistory } from "@reach/router"

import Header from "./header"
import Images from "./image"
import PageLoader from "./page-loader"
import "./layout.css"
import "./font-awesome.css"
import "./src-css/base.scss"
import "./src-css/content.scss"
import "./src-css/responsive.scss"


const initialState = {
  contactBackButtonDisplay: true,
}

const Layout = ({ children }) => {

  const pageName = globalHistory.location.pathname

  console.log(pageName)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Provider globalState={initialState}>
    <>
      {/*<Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >*/}
        <PageLoader />
        <main>{children}</main>
        {/*<footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>
      </div>*/}
    </>

 </Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


export default Layout
