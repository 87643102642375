import React, {Component} from "react"
import {globalHistory} from '@reach/router';

class PageLoader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false
    }
  }

  componentWillMount() {
  }

  componentDidMount() {
    this.setState({active: true})
  }

  render() {

    const pageIsLoaded = this.state.active;

    if (!pageIsLoaded) {
      return (<div className="bar-preload">
        <div className="loader">
          <span className="ball"></span>
          <span className="ball2"></span>
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>)
    } else {
      return (<> < />
      )
    }

  }
}


export default PageLoader
